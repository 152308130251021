@import "../../../mixins";

.front-banner {
  position: relative;
  margin-top: rem(-124);

  padding: rem(224) 0 rem(142);
  width: 100%;
  min-height: rem(1100);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  background-color: var(--bg-light);
  overflow: hidden;

  @include mediaBigDesktop {
    margin-top: big(-124);

    padding: big(224) 0 big(142);
    min-height: big(1100);
  }

  @include mediaDesktop {
    margin-top: rem(-114);

    padding: rem(194) 0 rem(120);
    min-height: rem(865);
  }

  @include mediaLaptop {
    margin-top: rem(-84);

    padding: rem(144) 0 rem(88);
    min-height: rem(672);
  }

  @include mediaTablet {
    margin-top: rem(-74);

    padding: rem(134) 0 rem(80);
    min-height: rem(607);
  }

  @include mediaMobile {
    margin-top: rem(-68);

    padding: rem(104) 0 rem(84);
    min-height: rem(585);
  }

  &__icon {
    position: absolute;
    top: rem(-293);
    right: var(--container-offset);
    z-index: 1;

    width: rem(1840);
    height: rem(1545);

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      top: big(-293);

      width: big(1840);
      height: big(1545);
    }

    @include mediaDesktop {
      top: rem(-216);

      width: rem(1377);
      height: rem(1142);
    }

    @include mediaLaptop {
      top: rem(-162);
      right: 0;
      left: 0;
      margin: auto;

      width: rem(1032);
      height: rem(856);
    }

    @include mediaTablet {
      right: unset;
      left: calc(var(--container-offset) * 2);
    }

    @include mediaMobile {
      top: rem(-48);
      left: rem(-94);

      width: rem(708);
      height: rem(588);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__gradient {
    --gradient-image: radial-gradient(138% 33% at 76% -4%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%);

    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
    user-select: none;

    &::before {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      padding-top: 66.6%;
      width: 100%;
      height: auto;

      background: var(--gradient-image);
      background-repeat: repeat;
      background-position: 100% center;
      background-size: cover;
    }
  }

  &__container {
    position: relative;
    z-index: 3;

    display: grid;
    grid-template-columns: var(--grid-default-columns);
    grid-template-rows: 1fr;
    row-gap: var(--grid-row-gap);
    column-gap: var(--grid-column-gap);

    @include mediaTablet {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__content {
    grid-column: 1/7;

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(106);

    @include mediaBigDesktop {
      gap: big(106);
    }

    @include mediaDesktop {
      grid-column: 1/8;

      gap: rem(50);
    }

    @include mediaLaptop {
      gap: rem(40);
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(60);

    @include mediaBigDesktop {
      gap: big(60);
    }

    @include mediaDesktop {
      max-width: rem(744);
      gap: rem(50);
    }

    @include mediaLaptop {
      max-width: rem(618);
      gap: rem(35);
    }

    @include mediaTablet {
      max-width: unset;
    }

    @include mediaMobile {
      gap: rem(20);
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaDesktop {
      gap: rem(30);
    }

    @include mediaLaptop {
      max-width: rem(555);
      gap: rem(20);
    }

    @include mediaTablet {
      max-width: unset;
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);

    @media (min-width: #{rem(1500)}) and (max-width: #{rem(2300)}) {
      --font-h1-main: 700 #{rem(70)}/1.1 var(--font-primary);
    }
  }

  &__description {
    width: 100%;
    max-width: rem(790);
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-L-r);

    @include mediaBigDesktop {
      max-width: big(790);
    }

    @include mediaDesktop {
      max-width: rem(615);
    }

    @include mediaLaptop {
      max-width: unset;
    }
  }

  &-form {
    width: 100%;
    max-width: rem(868);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(25);

    @include mediaBigDesktop {
      max-width: big(868);
      gap: big(25);
    }

    @include mediaDesktop {
      max-width: rem(726);
      gap: rem(18);
    }

    @include mediaLaptop {
      max-width: unset;
    }

    @include mediaMobile {
      gap: rem(15);
    }

    &__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: rem(15);

      @include mediaBigDesktop {
        gap: big(15);
      }

      @include mediaLaptop {
        gap: rem(10);
      }

      @include mediaMobile {
        flex-direction: column;
      }

      & .text-input {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
      }

      & .button {
        width: auto;
        flex-shrink: 0;

        @include mediaMobile {
          width: 100%;
        }
      }
    }

    & .checkbox {
      --checkbox-gap: #{rem(15)};
      --checkbox-size: #{rem(30)};

      align-items: center;

      @include mediaBigDesktop {
        --checkbox-gap: #{big(15)};
        --checkbox-size: #{big(30)};
      }

      @include mediaDesktop {
        --checkbox-size: #{rem(24)};
      }

      &__input {
        &:checked {
          & ~ .checkbox__item {
            background: var(--bg-white);
            border-color: var(--stroke-light);

            &::before {
              background: var(--accent-secondary-default);
            }
          }
        }
      }

      &__item {
        --accent-primary-default: var(--accent-secondary-default);

        border-width: 1px;

        &::before {
          transition: background-color var(--animation-default);
        }
      }

      & .text {
        font: 400 rem(16)/1.55 var(--font-primary);

        @include mediaBigDesktop {
          font: 400 big(16)/1.55 var(--font-primary);
        }

        @include mediaDesktop {
          font: 500 rem(14)/1.55 var(--font-primary);
        }

        & a {
          color: var(--text-dark-primary);
          text-decoration: underline;

          @include hover {
            color: var(--accent-secondary-default);
          }
        }
      }
    }
  }

  &-advantages {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaMobile {
      display: none;
    }

    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @include mediaDesktop {
        max-width: rem(244);
      }

      @include mediaLaptop {
        max-width: unset;
        gap: rem(2);
      }
    }

    &__count {
      width: 100%;
      display: block;

      color: var(--text-dark-primary);
      font: 700 rem(60)/1.5 var(--font-primary);

      @include mediaBigDesktop {
        font: 700 big(60)/1.5 var(--font-primary);
      }

      @include mediaDesktop {
        font: 700 rem(50)/1.5 var(--font-primary);
      }

      @include mediaLaptop {
        font: 700 rem(34)/1.5 var(--font-primary);
      }

      @include mediaTablet {
        font: 700 rem(30)/1.5 var(--font-primary);
      }

      & > span {
        font-size: rem(40);

        @include mediaBigDesktop {
          font-size: big(40);
        }

        @include mediaDesktop {
          font-size: rem(30);
        }

        @include mediaLaptop {
          font-size: rem(20);
        }
      }
    }

    &__description {
      width: 100%;
      display: block;

      color: var(--text-dark-secondary);
      font: var(--font-body-M-m);
    }
  }

  &-preview {
    position: absolute;
    top: rem(157);
    left: 53.6%;
    z-index: 3;

    width: rem(1101);
    height: rem(778.5);
    display: flex;

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      top: big(157);

      width: big(1101);
      height: big(778.5);
    }

    @include mediaDesktop {
      top: rem(124);

      width: rem(850);
      height: rem(601);
    }

    @include mediaLaptop {
      top: rem(122);
      left: 53.9%;

      width: rem(635);
      height: rem(449);
    }

    @include mediaTablet {
      display: none;
    }

    &__image {

      & img,
      & picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        margin: auto;

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      /*& picture {
        transform: scale(-1, 1);
      }*/

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &__adv {
      --font-banner-adv-background: var(--bg-white);
      --front-banner-adv-color: var(--accent-primary-default);

      position: absolute;
      top: rem(190);
      left: rem(39);
      margin: 0;

      padding: rem(25);
      width: rem(168);
      height: rem(168);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      color: var(--front-banner-adv-color);
      font: 800 rem(32)/1.5 var(--font-primary);
      text-align: center;
      text-decoration: none;

      background-color: var(--font-banner-adv-background);
      border-radius: var(--radius-infinity);

      transition: background-color var(--animation-default), color var(--animation-default);

      @include mediaBigDesktop {
        top: big(190);
        left: big(39);

        padding: big(25);
        width: big(168);
        height: big(168);

        font-size: big(32);
      }

      @include mediaDesktop {
        top: rem(147);
        left: rem(30);

        width: rem(130);
        height: rem(130);

        font-size: rem(24);
      }

      @include mediaLaptop {
        top: rem(112);
        left: rem(22.5);

        width: rem(100);
        height: rem(100);

        font-size: rem(18);
      }

      &_white {
        &_link {
          pointer-events: all;

          @include hover {
            --font-banner-adv-background: var(--accent-primary-default);
            --front-banner-adv-color: var(--bg-white);
          }
        }
      }

      &_accent {
        --font-banner-adv-background: var(--accent-primary-default);
        --front-banner-adv-color: var(--bg-white);

        top: rem(587);
        left: rem(535);

        @include mediaBigDesktop {
          top: big(587);
          left: big(535);
        }

        @include mediaDesktop {
          top: rem(454);
          left: rem(414);
        }

        @include mediaLaptop {
          top: rem(340);
          left: rem(309);
        }

        &_link {
          pointer-events: all;

          @include hover {
            --font-banner-adv-background: var(--bg-white);
            --front-banner-adv-color: var(--accent-primary-default);
          }
        }
      }

      & > span {
        font-size: rem(22);
        font-weight: 600;

        @include mediaBigDesktop {
          font-size: big(22);
        }

        @include mediaDesktop {
          font-size: rem(16);
        }

        @include mediaLaptop {
          font-size: rem(14);
        }
      }
    }
  }
}
